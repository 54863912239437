<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>{{ $t("comingSoon") }}</span>
              </label>
            </v-card-title>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row></v-container
  >
</template>

<script>
export default {
  name: "SearchInventory",
};
</script>